var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation
    ? _c(
        "section",
        [
          _c(
            "b-nav",
            { staticClass: "hidden-mobile", attrs: { tabs: "" } },
            [
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.tab == 0 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 0
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("ALL_MEETINGS")))]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.tab == 1 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 1
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.teams-meeting")))]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.tab == 2 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 2
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.jitsi-secure-meeting")))]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.tab == 3 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 3
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.jitsi-meeting")))]
              ),
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.tab == 4 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 4
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.nuiteq-meeting")))]
              ),
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "hidden-not-mobile",
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _vm.tab == 0
                          ? _c("span", [_vm._v(_vm._s(_vm.$t("ALL_MEETINGS")))])
                          : _vm._e(),
                        _vm.tab == 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("MESSAGES.TYPE.teams-meeting"))
                              ),
                            ])
                          : _vm._e(),
                        _vm.tab == 2
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("MESSAGES.TYPE.jitsi-secure-meeting")
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm.tab == 3
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("MESSAGES.TYPE.jitsi-meeting"))
                              ),
                            ])
                          : _vm._e(),
                        _vm.tab == 4
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("MESSAGES.TYPE.nuiteq-meeting"))
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1639085222
              ),
            },
            [
              _c(
                "b-dropdown-item",
                {
                  attrs: { active: _vm.tab == 0 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 0
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("ALL_MEETINGS")))]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { active: _vm.tab == 1 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 1
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.teams-meeting")))]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { active: _vm.tab == 2 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 2
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.jitsi-secure-meeting")))]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { active: _vm.tab == 3 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 3
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.jitsi-meeting")))]
              ),
              _c(
                "b-dropdown-item",
                {
                  attrs: { active: _vm.tab == 4 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 4
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("MESSAGES.TYPE.nuiteq-meeting")))]
              ),
            ],
            1
          ),
          _vm.tab == 0
            ? _c("GeneralSetting", {
                attrs: { organisation: _vm.organisation },
              })
            : _vm._e(),
          _vm.tab == 1
            ? _c("MsTeamsSetting", {
                attrs: { organisation: _vm.organisation },
              })
            : _vm._e(),
          _vm.tab == 2
            ? _c("JitsiSecureSetting", {
                attrs: { organisation: _vm.organisation },
              })
            : _vm._e(),
          _vm.tab == 3
            ? _c("JitsiSetting", { attrs: { organisation: _vm.organisation } })
            : _vm._e(),
          _vm.tab == 4
            ? _c("NuiteqSetting", { attrs: { organisation: _vm.organisation } })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }