<template>
  <section v-if="information">
    <div class="mt-2" :key="application_uuid"> 

      <h3>
        {{ information.name }}<br />
      </h3>

      <b-nav tabs class="hidden-mobile">
        <b-nav-item :active="tab == 0" @click="tab = 0">{{$t('SETTINGS')}}</b-nav-item>
        <b-nav-item :active="tab == 1" @click="tab = 1">{{$t('API')}}</b-nav-item>
      </b-nav>
      
      <b-dropdown class="hidden-not-mobile">
        <template #button-content>
          <span v-if="tab == 0">{{$t('SETTINGS') }}</span>
          <span v-if="tab == 1">{{$t('API') }}</span>
        </template>
        <b-dropdown-item :active="tab == 0" @click="tab = 0">{{$t('SETTINGS')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 1" @click="tab = 1">{{$t('API')}}</b-dropdown-item>
      </b-dropdown>

      <Edit v-if="tab == 0"
        @updatedApplication="updatedApplication"
        @removedApplication="removedApplication"        
        :information="information"
        :organisation="organisation"
      ></Edit>    

      <API 
        :information="information"
        :organisation="organisation" v-if="tab == 1"></API>      

    </div>
  </section>
</template>
<script>
import Edit from "@/components/Organisation/Applications/Edit";
import API from "@/components/Organisation/Applications/API";
export default {
  props: {
    organisation: {
      type: Object,
      default: null
    },    
    application_uuid: {
      type: String,
      default: ""
    }
  },
  components: { Edit, API },
  data() {
    return {
      setting: 0,
      tab: 0,
      information: null
    };
  },
  methods: {
    updatedApplication: function() {
      this.getInformation();
    },
    removedApplication: function() {
      this.$emit("removedApplication");
    },
    getInformation: function() {
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/administration/application/get",
          {
            organisation_uuid: this.organisation.organisation_uuid,
            application_uuid: this.application_uuid
          }
        )
        .then((response) => {
          self.information = response.data;
        })
        .catch(() => {
        });
    }
  },
  watch: {
    application_uuid: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getInformation();
      }
    },
  },
  computed: {
  },
  mounted() {
    this.getInformation();
  },
};
</script>
<style></style>
