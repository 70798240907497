var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("section", [
        _c(
          "div",
          { key: _vm.application_uuid, staticClass: "mt-2" },
          [
            _c("h3", [
              _vm._v("\n      " + _vm._s(_vm.information.name)),
              _c("br"),
            ]),
            _c(
              "b-nav",
              { staticClass: "hidden-mobile", attrs: { tabs: "" } },
              [
                _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.tab == 0 },
                    on: {
                      click: function ($event) {
                        _vm.tab = 0
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("SETTINGS")))]
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.tab == 1 },
                    on: {
                      click: function ($event) {
                        _vm.tab = 1
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("API")))]
                ),
              ],
              1
            ),
            _c(
              "b-dropdown",
              {
                staticClass: "hidden-not-mobile",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm.tab == 0
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("SETTINGS")))])
                            : _vm._e(),
                          _vm.tab == 1
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("API")))])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3820225399
                ),
              },
              [
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { active: _vm.tab == 0 },
                    on: {
                      click: function ($event) {
                        _vm.tab = 0
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("SETTINGS")))]
                ),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { active: _vm.tab == 1 },
                    on: {
                      click: function ($event) {
                        _vm.tab = 1
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("API")))]
                ),
              ],
              1
            ),
            _vm.tab == 0
              ? _c("Edit", {
                  attrs: {
                    information: _vm.information,
                    organisation: _vm.organisation,
                  },
                  on: {
                    updatedApplication: _vm.updatedApplication,
                    removedApplication: _vm.removedApplication,
                  },
                })
              : _vm._e(),
            _vm.tab == 1
              ? _c("API", {
                  attrs: {
                    information: _vm.information,
                    organisation: _vm.organisation,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }