var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation
    ? _c(
        "section",
        { staticClass: "mt-3" },
        [
          _vm.settings
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("STATUS_ACTIVE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          value: true,
                          "unchecked-value": false,
                          switch: "",
                        },
                        model: {
                          value: _vm.settings.enabled,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "enabled", $$v)
                          },
                          expression: "settings.enabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("ENFORCE_SUBJECT"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        attrs: { trim: "" },
                        model: {
                          value: _vm.settings.subject,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "subject", $$v)
                          },
                          expression: "settings.subject",
                        },
                      }),
                      _vm.settings.subject
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-2",
                              attrs: { value: true, "unchecked-value": false },
                              model: {
                                value: _vm.settings.unlock_subject,
                                callback: function ($$v) {
                                  _vm.$set(_vm.settings, "unlock_subject", $$v)
                                },
                                expression: "settings.unlock_subject",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("USER_CAN_UNLOCK")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("ENFORCE_EMAIL_BODY"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-textarea", {
                        model: {
                          value: _vm.settings.email_body,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "email_body", $$v)
                          },
                          expression: "settings.email_body",
                        },
                      }),
                      _vm.settings.email_body
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-2",
                              attrs: { value: true, "unchecked-value": false },
                              model: {
                                value: _vm.settings.unlock_email_body,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settings,
                                    "unlock_email_body",
                                    $$v
                                  )
                                },
                                expression: "settings.unlock_email_body",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("USER_CAN_UNLOCK")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("BUTTON_TEXT"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-input", {
                        attrs: { trim: "" },
                        model: {
                          value: _vm.settings.send_btn_text,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "send_btn_text", $$v)
                          },
                          expression: "settings.send_btn_text",
                        },
                      }),
                      _c("b-form-text", [
                        _vm._v(_vm._s(_vm.$t("BUTTON_TEXT_DESCRIPTION"))),
                      ]),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { variant: "primary" },
                      on: { click: _vm.updateSettings },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }