var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation_uuid
    ? _c(
        "section",
        { staticClass: "mt-3" },
        [
          _vm.settings
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("STATUS_ACTIVE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          value: true,
                          switch: "",
                          "unchecked-value": false,
                        },
                        model: {
                          value: _vm.settings.enabled,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "enabled", $$v)
                          },
                          expression: "settings.enabled",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.functionbox_uuid
                    ? _c("div", [
                        _c("h5", { staticClass: "mt-3" }, [
                          _vm._v(_vm._s(_vm.$t("ONLY_ADMIN_CAN_EDIT"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  switch: "",
                                  value: true,
                                  "unchecked-value": false,
                                },
                                model: {
                                  value: _vm.settings.admin_edit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.settings, "admin_edit", $$v)
                                  },
                                  expression: "settings.admin_edit",
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("STATUS_ACTIVE")))]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("h5", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("ADDRESSBOOK.ALLOW_IDENTIFIER"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            value: true,
                            switch: "",
                            "unchecked-value": false,
                          },
                          model: {
                            value: _vm.settings.use_identifier,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "use_identifier", $$v)
                            },
                            expression: "settings.use_identifier",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("YES")))]
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("h5", { staticClass: "mt-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("ADDRESSBOOK.ALLOWED_IDENTIFIER_FORMAT"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [
                        _c("b-form-checkbox-group", {
                          staticClass: "mt-2",
                          attrs: {
                            id: "radio-group-1",
                            options: _vm.use_identifier_formats,
                          },
                          model: {
                            value: _vm.use_identifier_format,
                            callback: function ($$v) {
                              _vm.use_identifier_format = $$v
                            },
                            expression: "use_identifier_format",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: {
                        variant: "primary",
                        disabled: _vm.updateSettingsDisabled,
                      },
                      on: { click: _vm.updateSettings },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }