<template>
  <section v-if="organisation">
      <b-tabs>          
        <b-tab lazy :title="$t('ALL_MESSAGES')" active>
          <GeneralSetting :organisation="organisation"></GeneralSetting>
        </b-tab>
        <b-tab lazy :title="$t('MESSAGES.TYPE.message')">
          <SettingSefosMessage :organisation_uuid="organisation.organisation_uuid"></SettingSefosMessage>
        </b-tab>
      </b-tabs>
  </section>
</template>
<script>
import GeneralSetting from '@/components/Organisation/Message/GeneralSetting';
import SettingSefosMessage from '@/components/Setting/SefosMessage';
export default {
  props: ["organisation"],
  components: {
    GeneralSetting,
    SettingSefosMessage
  },
  data() {
    return {
    };
  },
  methods: {
  },
  watch: { 
  },
  computed: {
  },
  mounted() {
  }
};
</script>
<style></style>