<template>
  <section>
    <b-overlay :show="loading" rounded="sm">

      <b-nav tabs class="hidden-mobile">
        <b-nav-item :active="tab == 0" @click="tab = 0">{{$t('ORGANISATION.TITLE')}}</b-nav-item>
        <b-nav-item :active="tab == 1" @click="tab = 1" v-if="information.organisation_uuid != ''">{{$t('MESSAGE')}}</b-nav-item>
        <b-nav-item :active="tab == 2" @click="tab = 2" v-if="information.organisation_uuid != ''">{{$t('MEETING')}}</b-nav-item>
        <b-nav-item :active="tab == 3" @click="tab = 3" v-if="information.organisation_uuid != ''">{{$t('ADDRESSBOOK.TITLE')}}</b-nav-item>
        <b-nav-item :active="tab == 4" @click="tab = 4" v-if="information.organisation_uuid != ''">{{$t('FILESTORAGE.TITLE')}}</b-nav-item>
        <b-nav-item :active="tab == 5" @click="tab = 5" v-if="information.organisation_uuid != ''">{{$t('APPLICATIONS')}}</b-nav-item>
      </b-nav>

      <b-dropdown class="hidden-not-mobile">
        <template #button-content>
          <span v-if="tab == 0">{{$t('ORGANISATION.TITLE')}}</span>
          <span v-if="tab == 1">{{$t('MESSAGE')}}</span>
          <span v-if="tab == 2">{{$t('MEETING')}}</span>
          <span v-if="tab == 3">{{$t('ADDRESSBOOK.TITLE')}}</span>
          <span v-if="tab == 4">{{$t('FILESTORAGE.TITLE')}}</span>
          <span v-if="tab == 5">{{$t('APPLICATIONS')}}</span>
        </template>
        <b-dropdown-item :active="tab == 0" @click="tab = 0">{{$t('ORGANISATION.TITLE')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 1" @click="tab = 1" v-if="information.organisation_uuid != ''">{{$t('MESSAGE')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 2" @click="tab = 2" v-if="information.organisation_uuid != ''">{{$t('MEETING')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 3" @click="tab = 3" v-if="information.organisation_uuid != ''">{{$t('ADDRESSBOOK.TITLE')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 4" @click="tab = 4" v-if="information.organisation_uuid != ''">{{$t('FILESTORAGE.TITLE')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 5" @click="tab = 5" v-if="information.organisation_uuid != ''">{{$t('APPLICATIONS')}}</b-dropdown-item>
      </b-dropdown>

      <OrganisationEdit v-if="tab == 0" :organisation="organisation"></OrganisationEdit>
      <MessagePage v-if="tab == 1 && information.organisation_uuid != ''"  :organisation="organisation"></MessagePage>
      <MeetingPage v-if="tab == 2 && information.organisation_uuid != ''"  :organisation="organisation"></MeetingPage>
      <SettingAddressBook v-if="tab == 3 && information.organisation_uuid != ''"  :organisation_uuid="organisation.organisation_uuid"></SettingAddressBook>
      <FileStorageSettings v-if="tab == 4 && information.organisation_uuid != ''"  :organisation="organisation"></FileStorageSettings>
      <ApplicationsList v-if="tab == 5 && information.organisation_uuid != ''"  :organisation="organisation"></ApplicationsList>

    </b-overlay>
  </section>
</template>
<script>
import OrganisationEdit from "@/components/Organisation/Edit";
import SettingAddressBook from "@/components/Setting/AddressBook";
import MeetingPage from "@/components/Organisation/Meeting/Page";
import FileStorageSettings from "@/components/Organisation/FileStorage/Page";
import MessagePage from "@/components/Organisation/Message/Page";
import ApplicationsList from "@/components/Organisation/Applications/List";
export default {
  components: { OrganisationEdit, MessagePage, MeetingPage, SettingAddressBook, FileStorageSettings, ApplicationsList },
  props: ["organisation"],
  data() {
    return {
      nuiteq: {
        client_key: '',
        enabled: false,
        hostname: '',
        user_meetings_enabled: 0
      },
      tab: 0,
      information: this.organisation,
      loading: false,
      show: false,
      name: "",
      validated: 0,
      users_can_remove: 1,
      unique_identifier: "",
      token_timout: 480,
      message_retention: 0,
      is_admin: 0,
      state: 0,
      message_response_default: 1,
      message_response_enabled: 1,
      email_account_privacy: 1,
      email_signature: "",
      email_edit_information_text: 1,
      email_information_text: "",
      email_notification_opened: 1,
      email_notification_responded: 1,
      meeting_secured_subject: 1,
      meeting_secured_body: 1,
      meeting_secured_enabled: 1,
      meeting_retention: 1,
      meeting_jitsi_enabled: 1,
      meeting_teams_enabled: 1,
      out_of_office_enabled: true,
      meeting_secured_send_ics: 1,
      meeting_secured_send_btn_text: "",
      cost_center: "",
      billing_receiver: "",
      accounts_can_delegate: 1,
      lang: "sv"
    };
  },
  methods: {
    editBtn: function () {
      let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/organisation/settings/edit", {
          name: this.name,
          lang: this.lang,
          unique_identifier: this.unique_identifier,
          state: parseInt(this.state),
          organisation_id: this.information.id,
          users_can_remove: this.users_can_remove,
          token_timout: parseInt(this.token_timout),
          message_retention: parseInt(this.message_retention),
          message_response_default: this.message_response_default,
          message_response_enabled: parseInt(this.message_response_enabled),
          email_account_privacy: this.email_account_privacy,
          email_signature: this.email_signature,
          email_information_text: this.email_information_text,
          email_edit_information_text: parseInt(
            this.email_edit_information_text
          ),
          email_notification_privacy: this.email_notification_privacy,
          email_notification_opened: parseInt(this.email_notification_opened),
          email_notification_responded: parseInt(
            this.email_notification_responded
          ),
          meeting_secured_subject: this.meeting_secured_subject,
          meeting_secured_body: this.meeting_secured_body,
          meeting_secured_enabled: parseInt(this.meeting_secured_enabled),
          meeting_retention: this.meeting_retention,
          meeting_jitsi_enabled: parseInt(this.meeting_jitsi_enabled),
          meeting_teams_enabled: parseInt(this.meeting_teams_enabled),
          meeting_secured_send_ics: parseInt(this.meeting_secured_send_ics),
          out_of_office_enabled: this.out_of_office_enabled,
          meeting_secured_send_btn_text: this.meeting_secured_send_btn_text,
          accounts_can_delegate: parseInt(this.accounts_can_delegate),
          cost_center: this.cost_center,
          billing_receiver: this.billing_receiver
        })
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
          self.show = false;
        })
        .catch(function () {
        });
    },
    removeBtn: function () {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname +
                  "/administration/organisation/remove",
                {
                  organisation_id: self.information.id,
                }
              )
              .then(function () {
                self.$noty.info(self.$t("REMOVED"));
                self.$router
                  .push({ name: "Administration.Organisations" })
                  .catch(() => {});
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function () {
    this.lang = this.information.lang;
    this.is_admin = this.information.is_admin;
    this.state = this.information.state;
    this.name = this.information.name;
    this.validated = this.information.validated;
    this.unique_identifier = this.information.unique_identifier;
    this.users_can_remove = this.information.users_can_remove;
    this.token_timout = this.information.token_timout;
    this.message_retention = this.information.message_retention;
    this.loa1_text = this.information.loa1_text;
    this.loa2_text = this.information.loa2_text;
    this.loa3_text = this.information.loa3_text;
    this.loa1_enabled = this.information.loa1_enabled;
    this.loa2_enabled = this.information.loa2_enabled;
    this.loa3_enabled = this.information.loa3_enabled;
    this.message_response_default = this.information.message_response_default;
    this.message_response_enabled = this.information.message_response_enabled;
    this.email_account_privacy = this.information.email_account_privacy;
    this.email_signature = this.information.email_signature;
    this.email_edit_information_text =
    this.information.email_edit_information_text;
    this.email_information_text = this.information.email_information_text;
    this.email_notification_opened = this.information.email_notification_opened;
    this.email_notification_responded =
    this.information.email_notification_responded;
    this.meeting_secured_enabled = this.information.meeting_secured_enabled;
    this.meeting_secured_subject = this.information.meeting_secured_subject;
    this.meeting_secured_body = this.information.meeting_secured_body;
    this.meeting_retention = this.information.meeting_retention;
    this.email_notification_responded =
    this.information.email_notification_responded;
    this.meeting_jitsi_enabled = this.information.meeting_jitsi_enabled;
    this.meeting_teams_enabled = this.information.meeting_teams_enabled;
    this.out_of_office_enabled = this.information.out_of_office_enabled;
    this.meeting_secured_send_ics = this.information.meeting_secured_send_ics;
    this.meeting_secured_send_btn_text =
    this.information.meeting_secured_send_btn_text;
    this.accounts_can_delegate = this.information.accounts_can_delegate;
    this.cost_center = this.information.cost_center;
    this.billing_receiver = this.information.billing_receiver;
  },
};
</script>
<style></style>
