<template>
    <section v-if="organisation">
      
      <b-nav tabs class="hidden-mobile">
        <b-nav-item :active="tab == 0" @click="tab = 0">{{$t('ALL_MEETINGS')}}</b-nav-item>
        <b-nav-item :active="tab == 1" @click="tab = 1">{{$t('MESSAGES.TYPE.teams-meeting')}}</b-nav-item>
        <b-nav-item :active="tab == 2" @click="tab = 2">{{$t('MESSAGES.TYPE.jitsi-secure-meeting')}}</b-nav-item>
        <b-nav-item :active="tab == 3" @click="tab = 3">{{$t('MESSAGES.TYPE.jitsi-meeting')}}</b-nav-item>
        <b-nav-item :active="tab == 4" @click="tab = 4">{{$t('MESSAGES.TYPE.nuiteq-meeting')}}</b-nav-item>
      </b-nav>
      
      <b-dropdown class="hidden-not-mobile">
        <template #button-content>
          <span v-if="tab == 0">{{$t('ALL_MEETINGS') }}</span>
          <span v-if="tab == 1">{{$t('MESSAGES.TYPE.teams-meeting') }}</span>
          <span v-if="tab == 2">{{$t('MESSAGES.TYPE.jitsi-secure-meeting') }}</span>
          <span v-if="tab == 3">{{$t('MESSAGES.TYPE.jitsi-meeting') }}</span>
          <span v-if="tab == 4">{{$t('MESSAGES.TYPE.nuiteq-meeting') }}</span>
        </template>
        <b-dropdown-item :active="tab == 0" @click="tab = 0">{{$t('ALL_MEETINGS')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 1" @click="tab = 1">{{$t('MESSAGES.TYPE.teams-meeting')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 2" @click="tab = 2">{{$t('MESSAGES.TYPE.jitsi-secure-meeting')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 3" @click="tab = 3">{{$t('MESSAGES.TYPE.jitsi-meeting')}}</b-dropdown-item>
        <b-dropdown-item :active="tab == 4" @click="tab = 4">{{$t('MESSAGES.TYPE.nuiteq-meeting')}}</b-dropdown-item>
      </b-dropdown>

      <GeneralSetting v-if="tab == 0" :organisation="organisation"></GeneralSetting>
      <MsTeamsSetting v-if="tab == 1"  :organisation="organisation"></MsTeamsSetting>
      <JitsiSecureSetting v-if="tab == 2"  :organisation="organisation"></JitsiSecureSetting>
      <JitsiSetting v-if="tab == 3"  :organisation="organisation"></JitsiSetting>
      <NuiteqSetting v-if="tab == 4"  :organisation="organisation"></NuiteqSetting>

    </section>
  </template>
  <script>
  import GeneralSetting from '@/components/Organisation/Meeting/GeneralSetting';
  import JitsiSecureSetting from '@/components/Organisation/Meeting/JitsiSecureSetting';
  import MsTeamsSetting from '@/components/Organisation/Meeting/MsTeamsSetting';
  import JitsiSetting from '@/components/Organisation/Meeting/JitsiSetting';
  import NuiteqSetting from '@/components/Organisation/Meeting/NuiteqSetting';
  export default {
    props: ["organisation"],
    components: {
      GeneralSetting,
      JitsiSecureSetting,
      MsTeamsSetting,
      JitsiSetting,
      NuiteqSetting
    },
    data() {
      return {
        tab: 0
      };
    },
    methods: {
    },
    watch: { 
    },
    computed: {
    },
    mounted() {
    }
  };
  </script>
  <style></style>