<template>
  <section class="mt-3" v-if="organisation_uuid">
    <b-overlay :show="loading" rounded="sm" v-if="settings">

      <h5 class="mt-3">{{ $t("STATUS_ACTIVE") }}</h5>
      <div class="mt-1">
        <b-form-checkbox
          v-model="settings.enabled"
          :value="true"
          switch
          :unchecked-value="false"></b-form-checkbox>
      </div>

      <div v-if="functionbox_uuid">
        <h5 class="mt-3">{{ $t("ONLY_ADMIN_CAN_EDIT") }}</h5>
        <div class="mt-1">
          <b-form-checkbox
            v-model="settings.admin_edit"
            switch
            :value="true"
            :unchecked-value="false"
          >{{ $t('STATUS_ACTIVE') }}</b-form-checkbox>
        </div>
      </div>
      
      <h5 class="mt-3">{{ $t("ADDRESSBOOK.ALLOW_IDENTIFIER") }}</h5>
      <div class="mt-1">
        <b-form-checkbox
          v-model="settings.use_identifier"
          :value="true"
          switch
          :unchecked-value="false"
        >{{ $t('YES') }}</b-form-checkbox>
      </div>

      <div>
        <h5 class="mt-3">{{ $t("ADDRESSBOOK.ALLOWED_IDENTIFIER_FORMAT") }}</h5>
        <div class="mt-1">
          <b-form-checkbox-group
            class="mt-2"
            id="radio-group-1"
            v-model="use_identifier_format"
            :options="use_identifier_formats"
          ></b-form-checkbox-group>
        </div>
      </div>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" class="btn-fill" :disabled="updateSettingsDisabled" @click="updateSettings">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: {
    functionbox_uuid: {
      default: "",
      type: String,
    },
    organisation_uuid: {
      default: "",
      type: String,
    }
  },
  data() {
    return {
      settings: null,
      loading: false,
      use_identifier_format: [],
      use_identifier_formats22: [
        { text: this.$t("ALL"), value: "" },
        { text: this.$t("IDENTIFIERS.SE_EID"), value: 'SE_EID' },
      ],
      use_identifier_formats: [
        { text: this.$t("IDENTIFIERS.SE_EID"), value: 'SE_EID' },
      ]
    };
  },
  methods: {
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(
          this.user.hostname +
            "/settings/get/"
          , {
            setting: "AddressBook", 
            functionbox_uuid: this.functionbox_uuid,
            organisation_uuid: this.organisation_uuid,
            user_uuid: ""
          }
        )
        .then(function (result) {
          self.settings = result.data;
          self.use_identifier_format = result.data.use_identifier_format.split(",");
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    updateSettings() {
      let self = this;
      self.loading = true;
      self.settings.use_identifier_format = self.use_identifier_format.join(',');
      this.$http
        .post(this.user.hostname + "/settings/save", self.settings)
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  computed: {
    updateSettingsDisabled(){
      if(this.settings.use_identifier == 1)
      {
        if(this.use_identifier_format.length == 0)
        {
          return true;
        }
      }
      return false;
    }
  },
  mounted: function () {
    this.getSettings();
  },
};
</script>
<style></style>
