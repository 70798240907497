var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation
    ? _c(
        "section",
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    lazy: "",
                    title: _vm.$t("ALL_MESSAGES"),
                    active: "",
                  },
                },
                [
                  _c("GeneralSetting", {
                    attrs: { organisation: _vm.organisation },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { lazy: "", title: _vm.$t("MESSAGES.TYPE.message") } },
                [
                  _c("SettingSefosMessage", {
                    attrs: {
                      organisation_uuid: _vm.organisation.organisation_uuid,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }