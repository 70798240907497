<template>
  <section class="mt-2" :key="tableKey">
    <b-overlay :show="loading" rounded="sm">
      <div v-if="added">
        <pre></pre>

        <b-card>
          <b-card-sub-title class="ml-0 pl-0"
            >{{ $t('NEW_API') }}</b-card-sub-title
          >
          <b-card-text>
            {{token}}
          </b-card-text>

          <b-button size="sm" @click="closeAdded">{{$t('CLOSE')}}</b-button>
        </b-card>
      </div>

      <div v-if="!added">
        <b-form inline class="mt-2">
          <b-button @click="refreshList" class="no-border"><i class="fal fa-sync"></i></b-button>
          <b-btn variant="dark" @click="organisationApi = true">
            {{ $t("ADD") }}
          </b-btn>
          <label class="sr-only" for="inline-form-input-search">{{$t('SEARCH')}}</label>
          <b-form-input trim
            v-model="searchText"
            :placeholder="$t('SEARCH')"
            @keydown.enter.prevent
            class="searchText hidden-mobile ml-0"
          ></b-form-input>

          
          <div class="hidden-not-mobile w-100 mt-2">
            <b-form inline>
              <b-form-input trim
                class="ml-2 i-100 searchText"
                v-model="searchText"
                :placeholder="$t('SEARCH')"
                @keydown.enter.prevent
              ></b-form-input>
            </b-form>
          </div>
        </b-form>

        <b-table
          small
          class="mt-2"
          :busy="loading"
          bordered
          :items="items"
          :fields="fields"
          @row-clicked="clickedRow"
        >

          <template #head(id)>
            <b-form-checkbox
              v-if="items.length != 0"
              v-model="remove_all"
              @change="removeAll"
            ></b-form-checkbox>
            &nbsp;
          </template>

          <template v-slot:cell(id)="data">
              <b-form-checkbox
                v-model="id_list"
                :value="data.item.id"
              ></b-form-checkbox>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>

        <b-pagination
          class="mt-2"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="limit"
          align="center"
          pills
          @input="getItems"
        >
        </b-pagination>

           <hr class="p-0 m-0 mt-2 mb-2" />

        <b-button
          :disabled="!validRemove"
          variant="danger"
          class="btn-fill"
          @click="removeBtn"
          >{{ $t("REMOVE") }}</b-button
        >
      </div>

      <b-sidebar id="application-sidebar" sidebar-class="big_sidebar" right backdrop no-slide shadow v-model="show_sidebar">
        <div class="px-3 py-2">
          <ApplicationsPage 
            v-if="application_uuid != ''"
            @removedApplication="removedApplication" 
            @updatedApplication="updatedApplication" 
            :organisation="organisation" 
            :application_uuid="application_uuid"
            :key="application_uuid">
          </ApplicationsPage>
        </div>
      </b-sidebar>
      
      <b-modal
        id="add-organisation-api"
        v-model="organisationApi"
        :dialog-class="teams.theme"
        hide-header
        @cancel="closeAdded"
        @ok="addBtn"
        centered
        :cancel-title="$t('CANCEL')"
        :ok-title="$t('ADD')"
      >
        <b-form-input trim
          autocomplete="off"
          :placeholder="$t('NAME')"
          v-model="AddApplicationDTO.name"
        ></b-form-input>

      
        <b-form-checkbox
        id="checkbox-1"
        class="mt-2"
        v-model="AddApplicationDTO.active"
        name="checkbox-1"
        :value="true"
        switch
        :unchecked-value="false"
      >
        {{ $t("STATUS_ACTIVE") }}
      </b-form-checkbox>

      </b-modal>
    </b-overlay>
  </section>
</template>
<script>
import ApplicationsPage from '@/components/Organisation/Applications/Page';
export default {
  components: { ApplicationsPage },
  props: ["organisation"],
  data() {
    return {
      application_uuid: "",
      show_sidebar: false,
      organisationApi: false,
      add_description: "",
      tableIndex: 0,
      added: false,
      id_list: [],
      token: "",
      remove_all: false,
      loading: false,
      searchText: "",
      limit: 10,
      currentPage: 1,
      items: [],
      totalItems: 0,
      AddApplicationDTO: {
        organisation_uuid: "",
        name: "",
        active: true
      },
      fields: [
        {
          key: "id",
          class: "w-45",
          thClass: "",
        },
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break clickable",
          sortable: false,
        }
      ]
    };
  },
  methods: {
    updatedApplication: function() {
      this.application_uuid = '';
      this.show_sidebar = false;
      this.getItems();
    },
    removedApplication() {
      this.application_uuid = '';
      this.show_sidebar = false;
      this.getItems();
    },
    closeAdded: function()
    {
      this.AddApplicationDTO.name = "";
      this.AddApplicationDTO.active = true;
      this.refreshList();
    },
    refreshList: function(){
      this.getItems();
      this.tableIndex++;
    },
    removeAll: function() {
      if (this.remove_all) {
        this.id_list = this.items.map((a) => a.id);
      } else {
        this.id_list = [];
      }
    },
    addBtn: function() {
      this.loading = true;
      let self = this;
      this.AddApplicationDTO.organisation_uuid = this.organisation.organisation_uuid;
      this.$http
        .post(this.user.hostname + "/administration/application/add",this.AddApplicationDTO)
        .then(() => {
          self.closeAdded();
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getItems: function() {
      this.application_uuid = "";
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/administration/application/list", {
          page: this.currentPage,
          organisation_uuid: this.organisation.organisation_uuid,
          limit: parseInt(this.limit),
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    removeBtn: function() {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function(value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/administration/application/remove",
                {
                  id_list: self.id_list,
                  organisation_uuid: self.organisation.organisation_uuid
                }
              )
              .then(() => {
                self.getItems();
                self.refreshList();
                self.id_list = [];
                self.loading = false;
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function() {});
    },
    clickedRow: function(record) {
      console.log(record);
      this.application_uuid = '';
      this.application_uuid = record.application_uuid;
      this.show_sidebar= true; 
    }
  },
  computed: {
    tableKey: function(){
      return this.tableIndex + "-api";
    },
    validRemove: function() {
      return this.id_list != 0;
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
<style></style>
