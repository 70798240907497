<template>
  <section class="mt-3" v-if="organisation">
    <b-overlay :show="loading" rounded="sm" v-if="settings">
      
      <h5 class="mt-3">{{ $t("STATUS_ACTIVE") }}</h5>
      <div class="mt-1">
        <b-form-checkbox
          v-model="settings.enabled"
          switch
          :value="true"
          :unchecked-value="false"
        >{{ $t('STATUS_ACTIVE') }}</b-form-checkbox>
      </div>

      <h5 class="mt-3">{{ $t("ENFORCE_SUBJECT") }}</h5>
      <div class="mt-1">
        <b-form-input trim v-model="settings.subject"></b-form-input>
        <b-form-checkbox class="mt-2"
          v-if="settings.subject"
          v-model="settings.unlock_subject"
          :value="true"
          :unchecked-value="false"
          switch
        >{{ $t('USER_CAN_UNLOCK') }}</b-form-checkbox>
      </div>

      <h5 class="mt-3">{{ $t("ENFORCE_EMAIL_BODY") }}</h5>
      <div class="mt-1">
        <b-form-textarea v-model="settings.email_body"></b-form-textarea>

        <b-form-checkbox class="mt-2"
          v-if="settings.email_body"
          v-model="settings.unlock_email_body"
          :value="true"
          :unchecked-value="false"
          switch
        >{{ $t('USER_CAN_UNLOCK') }}</b-form-checkbox>
      </div>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="updateSettings">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      settings: null,
      loading: false,
    };
  },
  methods: {
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/get", {
          setting: "MsTeams",
          organisation_uuid: this.organisation.organisation_uuid,
          functionbox_uuid: "",
        }
        )
        .then(function (result) {
          self.settings = result.data;
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    updateSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/save", self.settings)
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getSettings();
  },
};
</script>
<style></style>
