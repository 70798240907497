<template>
  <section class="mt-2">
    <b-overlay :show="loading" rounded="sm">

      <h5 class="mt-3">{{ $t("NAME") }}</h5>
      <div class="mt-1">
        <b-form-input
          trim
          required
          type="text"
          v-model="information.name"
          name="name"
        ></b-form-input>
      </div>

      <h5 class="mt-3">{{ $t("STATUS_ACTIVE") }}</h5>
      <div class="mt-1">
        <b-form-checkbox
          id="checkbox-1"
          class="mt-2"
          v-model="information.active"
          name="checkbox-1"
          :value="true"
          switch
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </div>
    
      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>

      <b-button
        variant="danger"
        class="btn-fill float-right"
        @click="removeSubmit"
        >{{ $t("REMOVE") }}</b-button
      >
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["information", "organisation"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    saveSubmit: function () {
      let self = this;
      this.loading = true;
      this.$http
        .post(
          this.user.hostname + "/administration/application/update",
          this.information
        )
        .then(function (result) {
          self.$noty.info(self.$t("SAVED"));
          self.$emit("updatedApplication", result.data);
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    removeSubmit: function () {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(self.user.hostname + "/administration/application/remove", {
                idList: [self.information.id],
                organisation_uuid: self.organisation.organisation_uuid,
              })
              .then(function () {
                self.$emit("removedUser");
                self.$noty.info(self.$t("REMOVED"));
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {},
  mounted: function () {},
};
</script>
<style></style>
