var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c(
            "b-nav",
            { staticClass: "hidden-mobile", attrs: { tabs: "" } },
            [
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.tab == 0 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 0
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE")))]
              ),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.tab == 1 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 1
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MESSAGE")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.tab == 2 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 2
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MEETING")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.tab == 3 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 3
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("ADDRESSBOOK.TITLE")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.tab == 4 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 4
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("FILESTORAGE.TITLE")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-nav-item",
                    {
                      attrs: { active: _vm.tab == 5 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 5
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("APPLICATIONS")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "hidden-not-mobile",
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _vm.tab == 0
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE"))),
                          ])
                        : _vm._e(),
                      _vm.tab == 1
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("MESSAGE")))])
                        : _vm._e(),
                      _vm.tab == 2
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("MEETING")))])
                        : _vm._e(),
                      _vm.tab == 3
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("ADDRESSBOOK.TITLE"))),
                          ])
                        : _vm._e(),
                      _vm.tab == 4
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("FILESTORAGE.TITLE"))),
                          ])
                        : _vm._e(),
                      _vm.tab == 5
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("APPLICATIONS")))])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-dropdown-item",
                {
                  attrs: { active: _vm.tab == 0 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 0
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE")))]
              ),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: { active: _vm.tab == 1 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 1
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MESSAGE")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: { active: _vm.tab == 2 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 2
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MEETING")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: { active: _vm.tab == 3 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 3
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("ADDRESSBOOK.TITLE")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: { active: _vm.tab == 4 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 4
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("FILESTORAGE.TITLE")))]
                  )
                : _vm._e(),
              _vm.information.organisation_uuid != ""
                ? _c(
                    "b-dropdown-item",
                    {
                      attrs: { active: _vm.tab == 5 },
                      on: {
                        click: function ($event) {
                          _vm.tab = 5
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("APPLICATIONS")))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.tab == 0
            ? _c("OrganisationEdit", {
                attrs: { organisation: _vm.organisation },
              })
            : _vm._e(),
          _vm.tab == 1 && _vm.information.organisation_uuid != ""
            ? _c("MessagePage", { attrs: { organisation: _vm.organisation } })
            : _vm._e(),
          _vm.tab == 2 && _vm.information.organisation_uuid != ""
            ? _c("MeetingPage", { attrs: { organisation: _vm.organisation } })
            : _vm._e(),
          _vm.tab == 3 && _vm.information.organisation_uuid != ""
            ? _c("SettingAddressBook", {
                attrs: {
                  organisation_uuid: _vm.organisation.organisation_uuid,
                },
              })
            : _vm._e(),
          _vm.tab == 4 && _vm.information.organisation_uuid != ""
            ? _c("FileStorageSettings", {
                attrs: { organisation: _vm.organisation },
              })
            : _vm._e(),
          _vm.tab == 5 && _vm.information.organisation_uuid != ""
            ? _c("ApplicationsList", {
                attrs: { organisation: _vm.organisation },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }