var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _vm.user.information.is_admin == 2
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("ORGANISATION.PARENT")) + "\n    "
                ),
              ])
            : _vm._e(),
          _vm.user.information.is_admin == 2
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  !_vm.parent_org
                    ? _c(
                        "b-button",
                        {
                          staticClass: "no-border",
                          attrs: { size: "sm" },
                          on: {
                            click: function ($event) {
                              _vm.chooseParent = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("CHOOSE")))]
                      )
                    : _vm._e(),
                  _vm.parent_org
                    ? _c(
                        "b-button",
                        {
                          staticClass: "no-border",
                          attrs: { size: "sm" },
                          on: {
                            click: function ($event) {
                              _vm.chooseParent = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("CHANGE")))]
                      )
                    : _vm._e(),
                  _vm.parent_org
                    ? _c("span", [_vm._v(_vm._s(_vm.parent_org.name))])
                    : _vm._e(),
                  !_vm.parent_org
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("NONE_SELECTED")))])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin == 2
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v("\n      " + _vm._s(_vm.$t("NAME")) + "\n    "),
              ])
            : _vm._e(),
          _vm.user.information.is_admin == 2
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "name",
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin == 2
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("LANGUAGE.TITLE")) + "\n    "
                ),
              ])
            : _vm._e(),
          _vm.user.information.is_admin == 2
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { name: "edit_user_select_language" },
                      model: {
                        value: _vm.lang,
                        callback: function ($$v) {
                          _vm.lang = $$v
                        },
                        expression: "lang",
                      },
                    },
                    _vm._l(_vm.sefos_locales, function (value, key) {
                      return _c(
                        "b-form-select-option",
                        { key: key, attrs: { value: key } },
                        [_vm._v(_vm._s(value))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin == 2
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("ORGANISATION.UNIQUE_IDENTIFIER")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm.user.information.is_admin == 2
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "email",
                    },
                    model: {
                      value: _vm.unique_identifier,
                      callback: function ($$v) {
                        _vm.unique_identifier = $$v
                      },
                      expression: "unique_identifier",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin == 2 &&
          _vm.user.information.organisation.id != _vm.information.id
            ? _c("h5", { staticClass: "mt-3" }, [
                _vm._v("\n      " + _vm._s(_vm.$t("STATE")) + "\n    "),
              ])
            : _vm._e(),
          _vm.user.information.is_admin == 2 &&
          _vm.user.information.organisation.id != _vm.information.id
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.stateOptions },
                    model: {
                      value: _vm.state,
                      callback: function ($$v) {
                        _vm.state = $$v
                      },
                      expression: "state",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("TOKEN_TIMOUT"))),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-input", {
                staticClass: "w-150",
                attrs: { trim: "", type: "number", name: "editTokenTimeOut" },
                model: {
                  value: _vm.token_timout,
                  callback: function ($$v) {
                    _vm.token_timout = $$v
                  },
                  expression: "token_timout",
                },
              }),
              _vm._v("\n      " + _vm._s(_vm.$t("MINUTES")) + "\n    "),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("ACTIVITY_TIMEOUT"))),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-input", {
                staticClass: "w-150",
                attrs: {
                  trim: "",
                  state: _vm.activity_timeout >= 5 || _vm.activity_timeout == 0,
                  type: "number",
                  name: "editActivityTimeOut",
                },
                model: {
                  value: _vm.activity_timeout,
                  callback: function ($$v) {
                    _vm.activity_timeout = $$v
                  },
                  expression: "activity_timeout",
                },
              }),
              _vm._v("\n      " + _vm._s(_vm.$t("MINUTES")) + "\n      "),
              _c("b-form-text", [
                _vm._v(
                  _vm._s(_vm.$t("ACTIVITY_TIMEOUT_MINIMUM")) +
                    ",\n        " +
                    _vm._s(_vm.$t("0_DISABLED"))
                ),
              ]),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.REMOVE_ACCOUNT_INFORMATION"))),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-checkbox", {
                attrs: { switch: "", value: "1", "unchecked-value": "0" },
                model: {
                  value: _vm.email_account_privacy,
                  callback: function ($$v) {
                    _vm.email_account_privacy = $$v
                  },
                  expression: "email_account_privacy",
                },
              }),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("OUT_OF_OFFICE"))),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-checkbox", {
                attrs: { switch: "", value: true, "unchecked-value": false },
                model: {
                  value: _vm.out_of_office_enabled,
                  callback: function ($$v) {
                    _vm.out_of_office_enabled = $$v
                  },
                  expression: "out_of_office_enabled",
                },
              }),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("EMAILSETTINGS.FORCE_SIGNATURE"))),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-textarea", {
                attrs: { placeholder: _vm.user.information.organisation.name },
                model: {
                  value: _vm.email_signature,
                  callback: function ($$v) {
                    _vm.email_signature = $$v
                  },
                  expression: "email_signature",
                },
              }),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("COST_CENTER"))),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-input", {
                attrs: { trim: "", type: "text", name: "editCostCenter" },
                model: {
                  value: _vm.cost_center,
                  callback: function ($$v) {
                    _vm.cost_center = $$v
                  },
                  expression: "cost_center",
                },
              }),
            ],
            1
          ),
          _c("h5", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(_vm.$t("BILLING_RECIPIENT"))),
          ]),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("b-form-input", {
                attrs: {
                  trim: "",
                  type: "text",
                  placeholder: _vm.$t("BILLING_RECIPIENT_DESCRIPTION"),
                },
                model: {
                  value: _vm.billing_receiver,
                  callback: function ($$v) {
                    _vm.billing_receiver = $$v
                  },
                  expression: "billing_receiver",
                },
              }),
              _c("b-form-text", [
                _vm._v(_vm._s(_vm.$t("BILLING_RECIPIENT_DESCRIPTION"))),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary", disabled: _vm.disableSave },
              on: { click: _vm.editBtn },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
          _vm.user.information.organisation.id != _vm.information.id
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill float-right",
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.showRemoveOrganisation = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "dialog-class": _vm.teams.theme,
            "hide-header": "",
            "ok-disabled": _vm.okRemoveDisabled,
            centered: "",
            "ok-variant": "danger",
            "cancel-title": _vm.$t("CANCEL"),
            "ok-title": _vm.$t("REMOVE"),
          },
          on: {
            cancel: _vm.cancelRemoveOrganisation,
            ok: _vm.removeOrganisation,
          },
          model: {
            value: _vm.showRemoveOrganisation,
            callback: function ($$v) {
              _vm.showRemoveOrganisation = $$v
            },
            expression: "showRemoveOrganisation",
          },
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE_ORGANISATION")
              ),
            },
          }),
          _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "input-1",
                label: _vm.$t("REMOVE_ORGANISATION_NAME"),
                "label-for": "input-2",
              },
            },
            [
              _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _c("b-form-input", {
                attrs: {
                  trim: "",
                  "aria-describedby": "label-help-block",
                  placeholder: "",
                },
                model: {
                  value: _vm.remove_organisation_name,
                  callback: function ($$v) {
                    _vm.remove_organisation_name = $$v
                  },
                  expression: "remove_organisation_name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "choose-parent-organisation",
            "hide-header": "",
            "ok-only": "",
            centered: "",
            "ok-title": _vm.$t("CANCEL"),
          },
          on: { ok: _vm.closeChooseParent },
          model: {
            value: _vm.chooseParent,
            callback: function ($$v) {
              _vm.chooseParent = $$v
            },
            expression: "chooseParent",
          },
        },
        [
          _c("b-form-input", {
            staticClass: "mt-2",
            attrs: { trim: "", placeholder: _vm.$t("ORGANISATION.TITLE") },
            model: {
              value: _vm.search_organisation,
              callback: function ($$v) {
                _vm.search_organisation = $$v
              },
              expression: "search_organisation",
            },
          }),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: {
                disabled: _vm.search_organisation == "",
                variant: "primary",
              },
              on: { click: _vm.searchOrganisations },
            },
            [_vm._v(_vm._s(_vm.$t("FETCH_INFORMATION")))]
          ),
          _vm.searchItems.length != 0
            ? _c("b-table", {
                staticClass: "mt-2",
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.searchItems,
                  fields: _vm.searchFields,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(name)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "dark", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectOrganisation(data.item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("CHOOSE")))]
                          ),
                          _vm._v(
                            "\n        " + _vm._s(data.item.name) + "\n      "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1471242525
                ),
              })
            : _vm._e(),
          _vm.searchDone && _vm.searchItems.length == 0
            ? _c("div", [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("SDK.NOT_FOUND_SEARCH")) + "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }