var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { key: _vm.tableKey, staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _vm.added
            ? _c(
                "div",
                [
                  _c("pre"),
                  _c(
                    "b-card",
                    [
                      _c("b-card-sub-title", { staticClass: "ml-0 pl-0" }, [
                        _vm._v(_vm._s(_vm.$t("FUNCTIONBOX.NEW_API"))),
                      ]),
                      _c("b-card-text", [
                        _vm._v(
                          "\n          " + _vm._s(_vm.token) + "\n        "
                        ),
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm" },
                          on: { click: _vm.closeAdded },
                        },
                        [_vm._v(_vm._s(_vm.$t("CLOSE")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.added
            ? _c(
                "div",
                [
                  _c(
                    "b-form",
                    { staticClass: "mt-2", attrs: { inline: "" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "no-border",
                          on: { click: _vm.refreshList },
                        },
                        [_c("i", { staticClass: "fal fa-sync" })]
                      ),
                      _c(
                        "b-btn",
                        {
                          attrs: { variant: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.organisationApi = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("ADD")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inline-form-input-search" },
                        },
                        [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                      ),
                      _c("b-form-input", {
                        staticClass: "searchText hidden-mobile ml-0",
                        attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "hidden-not-mobile w-100 mt-2" },
                        [
                          _c(
                            "b-form",
                            { attrs: { inline: "" } },
                            [
                              _c("b-form-input", {
                                staticClass: "ml-2 i-100 searchText",
                                attrs: {
                                  trim: "",
                                  placeholder: _vm.$t("SEARCH"),
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.searchText,
                                  callback: function ($$v) {
                                    _vm.searchText = $$v
                                  },
                                  expression: "searchText",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-table", {
                    staticClass: "mt-2",
                    attrs: {
                      small: "",
                      busy: _vm.loading,
                      bordered: "",
                      items: _vm.items,
                      fields: _vm.fields,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "head(id)",
                          fn: function () {
                            return [
                              _vm.items.length != 0
                                ? _c("b-form-checkbox", {
                                    on: { change: _vm.removeAll },
                                    model: {
                                      value: _vm.remove_all,
                                      callback: function ($$v) {
                                        _vm.remove_all = $$v
                                      },
                                      expression: "remove_all",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v("\n           \n        "),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "cell(id)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-form",
                                { attrs: { inline: "" } },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { value: data.item.id },
                                    model: {
                                      value: _vm.id_list,
                                      callback: function ($$v) {
                                        _vm.id_list = $$v
                                      },
                                      expression: "id_list",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(description)",
                          fn: function (data) {
                            return [
                              _c("div", { staticClass: "hidden-not-mobile" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(data.item.description)
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("CREATED")) +
                                    " " +
                                    _vm._s(
                                      _vm.FormatDateTime(data.item.created_date)
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("EXPIRES")) +
                                    " " +
                                    _vm._s(
                                      _vm.FormatDateTime(data.item.expire_date)
                                    ) +
                                    "\n          "
                                ),
                              ]),
                              _c("div", { staticClass: "hidden-mobile" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(data.item.description) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(created_date)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.FormatDateTime(data.item.created_date)
                                )
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(expire_date)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.FormatDateTime(data.item.expire_date)
                                )
                              ),
                            ]
                          },
                        },
                        {
                          key: "table-busy",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center my-2" },
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle",
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2745317650
                    ),
                  }),
                  _c("b-pagination", {
                    staticClass: "mt-2",
                    attrs: {
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.limit,
                      align: "center",
                      pills: "",
                    },
                    on: { input: _vm.getItems },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                  _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { disabled: !_vm.validRemove, variant: "danger" },
                      on: { click: _vm.removeBtn },
                    },
                    [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                "dialog-class": _vm.teams.theme,
                id: "add-organisation-api",
                "hide-header": "",
                centered: "",
                "cancel-title": _vm.$t("CANCEL"),
                "ok-title": _vm.$t("ADD"),
              },
              on: { cancel: _vm.closeAdded, ok: _vm.addBtn },
              model: {
                value: _vm.organisationApi,
                callback: function ($$v) {
                  _vm.organisationApi = $$v
                },
                expression: "organisationApi",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  trim: "",
                  autocomplete: "off",
                  placeholder: _vm.$t("NAME"),
                },
                model: {
                  value: _vm.add_description,
                  callback: function ($$v) {
                    _vm.add_description = $$v
                  },
                  expression: "add_description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }